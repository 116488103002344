import { makeApiCall } from "../api/apiCalls";

export const handleTaskRequest = async (
  taskType,
  llmChoice,
  languageChoice,
  summaryStyleChoice,
  setTaskStates,
  taskStateValues,
  exchangeRate,
  chatHistory // Pass chatHistory as well
) => {
  const {
    //currentTask,
    prompt,
    brainstormHistory,
    chatHistory: legacyChatHistory,
    sessionData,
    setSessionData,
    updateTokenData,
  } = taskStateValues;

  // Determine temperature:
  let temperature = 0.7;
  if (taskType === "Summary" || taskType === "Translation") {
    temperature = 0.2;
  }

  // Set temperature to 1.3 if llmChoice is DeepSeek V3
  if (llmChoice === "DeepSeek V3") {
    temperature = 1.3;
  }

  // Determine max_tokens based on model groups
  const tokenLimits = {
    4096: ["GPT-3.5 Turbo"],
    8192: [
      "Claude 3.5 Haiku",
      "Claude 3.5 Sonnet",
      "Gemini 2.0 Flash",
      "DeepSeek V3",
    ],
    16384: ["GPT-4o (latest)", "GPT-4o", "GPT-4o mini"],
    32768: ["Groq Llama-3.3-70b-Versatile 128K"],
  };

  // Find the appropriate token limit for the chosen model
  let maxTokens = 4096; // default value
  for (const [limit, models] of Object.entries(tokenLimits)) {
    if (models.includes(llmChoice)) {
      maxTokens = parseInt(limit);
      break;
    }
  }

  let requestBody = {
    prompt: prompt,
    llm_model_choice: llmChoice,
    task_choice: taskType,
    language_choice: languageChoice,
    max_tokens: maxTokens,
    temperature: temperature,
    session_data: sessionData,
    usd_to_php_rate: exchangeRate.rate,
    rate_timestamp: exchangeRate.timestamp,
    chat_history: chatHistory, // Include chat_history from messages
  };

  if (taskType === "Summary" && summaryStyleChoice) {
    requestBody.summary_style_choice = summaryStyleChoice;
  }

  // We now have a unified endpoint:
  const endpointToUse = "/unified_generate/";

  const data = await makeApiCall(
    endpointToUse,
    requestBody,
    taskType,
    setTaskStates.setBrainstormHistory,
    setTaskStates.setChatHistory,
    setTaskStates.setNonChatDraftHistory,
    setTaskStates.setIsNewChatSession,
    setTaskStates.setGeneratedText,
    setTaskStates.setTokenInfo,
    setTaskStates.isNewChatSession,
    prompt,
    brainstormHistory,
    legacyChatHistory,
    setSessionData,
    updateTokenData
  );

  return data;
};
