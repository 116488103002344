import React from 'react';
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// Only change is adding shouldForwardProp
const StyledButtonBase = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isGenerating'
})(({ isGenerating }) => ({
  marginBottom: "10px",
  marginRight: "8px",
  marginLeft: "auto",
  borderRadius: "8px",
  fontSize: "12px",
  paddingTop: "0px",
  paddingBottom: "0px",
  paddingLeft: "10px",
  paddingRight: "10px",
  position: "relative",
  maxWidth: "200px",
  "&:disabled": {
    backgroundColor: "rgba(63, 81, 181, 0.5)", // Dimmed primary color
    color: "rgba(255, 255, 255, 0.5)", // Dimmed text color
  },
  "& .spinner": {
    position: "absolute",
    left: "45%",
    top: "25%",
    transform: "translate(-50%, -50%)",
    color: "white", // Keep spinner white
  },
  "& .button-text": {
    visibility: isGenerating ? "hidden" : "visible",
  },
}));

const StyledButton = ({ onClick, isGenerating, children, sx, ...props }) => (
  <StyledButtonBase
    variant="contained"
    color="primary"
    onClick={onClick}
    disabled={isGenerating}
    isGenerating={isGenerating}
    sx={{
      ...sx // This will properly merge with base styles
    }}
    {...props} // This will allow variant and color overrides
  >
    {isGenerating && (
      <FontAwesomeIcon icon={faSpinner} spin className="spinner" />
    )}
    <span className="button-text">{children}</span>
  </StyledButtonBase>
);

export default StyledButton;